<template>
  <section id="dashboard-historico-planificacion">

    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <ag-grid-table
          :configUrl="config"
          :dataUrl="data"
          :editUrl="edit"
          @gridReady="onGridReady"
          @getRowsLoaded="onGetRowsLoaded"
        >
        </ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>

<script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import RepeatStatistics from "@/views/clients/fiordo/components/RepeatStatistics";
import useApiServices from '@/services/useApiServices.js';
import PickingActions from "./PickingActions.vue";
import PlanificacionActions from "./PlanificacionActions.vue";
import DashPotencialActions from "./DashPotencialActions.vue";
import DashGlobalActions from "./DashGlobalActions.vue";
import axios from "@axios";
import
{
  BRow,
  BCol,

} from "bootstrap-vue";

export default {  
    components: {
        AgGridTable,
        BRow,
        BCol,
        RepeatStatistics,
        PickingActions: PickingActions,
        PlanificacionActions: PlanificacionActions,
        DashPotencialActions: DashPotencialActions,
        DashGlobalActions: DashGlobalActions
    },

    methods: { 

        onGridReady(gridApi, gridColumnApi, params) { 

            // console.log("Testtttttttttttt > onGridReady", {gridApi, gridColumnApi, params})

        },

        onGetRowsLoaded(gridApi, gridColumnApi, params)
        {
            // console.log("Planificaciones > onGetRowsLoaded", {gridApi, gridColumnApi, params})

            gridColumnApi.autoSizeColumn('actions')
        },

    },

    mounted()
    {
      axios.get(useApiServices.historicoPlanificacionesStatistics)
      .then((response) =>
      {
          this.itemsData = response.data;
      })
      .catch((error) =>
      {

      });
      console.log(useApiServices.historicoPlanificacionesConfig)
    },


    data()
    {
        return {

        itemsData: [],

        config: useApiServices.historicoPlanificacionesConfig,
        data: useApiServices.historicoPlanificacionesData,
        edit: useApiServices.historicoPlanificacionesEdit,

        };
    },
};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>